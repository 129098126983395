<template>
  <section class="lh-WrapperSingle">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--full">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link"
                >Charte de respect de la vie privée</a
              >
            </li>
          </ul>
          <div class="lh-Text">
            <h1>Charte de respect de la vie privée.</h1>

            <p class="lh-Text-date">
              Date de dernière mise à jour : 25/09/2021
            </p>
            <p>
              La présente charte sur le respect de la vie privée (la « Charte »)
              a pour objectif de formaliser notre engagement quant au respect de
              la vie privée des utilisateurs du site internet www.lhight.fr (le
              « Site ») exploité par la société LHight
            </p>
            <p>
              La Charte et les Conditions Générales du Site forment un ensemble
              contractuel. Tous les termes en majuscules non-définis dans la
              présente Charte sont définis dans les Conditions Générales
              consultables ici :
              <a href="https://www.lhight.fr/terms">Conditions Générales</a>.
            </p>
            <p>
              Dans le cadre de la mise à disposition de notre Site, nous
              traitons vos données à caractère personnel dans le respect du
              Règlement Général sur la Protection des Données 2016/679 du 27
              avril 2016 (« RGPD ») et dans les conditions exposées ci-après.
            </p>
            <p>
              Une donnée à caractère personnel désigne toute information se
              rapportant à une personne physique identifiée ou identifiable.
              Nous collectons et traitons des données à caractère personnel dans
              le cadre de la fourniture de nos Services ou de la communication à
              propose de ces Services exclusivement, en stricte conformité avec
              le RGPD.
            </p>
            <p>
              Nous collectons uniquement des données à caractère personnel
              adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées. Ainsi,
              il ne vous sera jamais demandé de renseigner des données à
              caractère personnel considérées comme « sensibles », telles que
              vos origines raciales ou ethniques, vos opinions politiques,
              philosophiques ou religieuses.
            </p>
            <p>
              En vous naviguant sur le Site, vous nous autorisez à traiter vos
              données à caractère personnel conformément à la Charte.
              <strong
                >Si vous refusez les termes de cette Charte, veuillez vous
                abstenir d’utiliser le Site ainsi que les Services.</strong
              >
            </p>

            <h2>
              1. Dans quels cas collectons-nous vos données à caractère
              personnel et quelles données sont collectées ?
            </h2>
            <p>
              Nous sommes susceptibles de recueillir et de conserver vos données
              à caractère personnel, notamment lorsque vous :
            </p>
            <ul>
              <li>naviguez sur le Site</li>
              <li>passez commande sur le Site</li>
              <li>nous contactez.</li>
            </ul>

            <p>
              Nous utilisons vos données à caractère personnel pour permettre la
              mise en œuvre et la gestion des Services du Site et répondre à vos
              demandes spécifiques. Nous utilisons également vos données à
              caractère personnel dans le but d'exploiter et d'améliorer nos
              Services, notre Site et notre démarche. Ces informations sont
              utilisées uniquement par nos soins et nous permettent de mieux
              adapter nos Services à vos attentes.
            </p>

            <p><strong>1.1 Navigation sur le Site</strong></p>
            <p>
              Données de navigation. Nous recueillons également les informations
              permettant d'identifier de quelle façon vous accédez au Site,
              quelles sont les pages consultées et pendant combien de temps.
              Dans ce cadre, nous pouvons avoir recours à l'utilisation de
              Cookies tel que précisé au paragraphe 6 ci-dessous.
            </p>

            <p><strong>1.2 Paiement</strong></p>
            <p>
              Certains des Services disponibles sont payants. A cette fin, vous
              acceptez que nous puissions avoir recours à des prestataires de
              services externes qui peuvent collecter des données à caractère
              personnel dans le but de permettre le bon fonctionnement des
              services de traitement des paiements par carte de crédit ou tout
              autre moyen de paiement et, le cas échéant, de livraison de
              produits ou services.
            </p>
            <p>
              Pour régler votre achat, vous devez fournir vos coordonnées de
              facturation ainsi que vos coordonnées de paiement, et notamment le
              numéro de votre carte bancaire, la date de validité, le code de
              sécurité et le nom du titulaire de la carte en cas de paiement par
              carte bancaire.
            </p>
            <p>
              Il peut également vous être demandé de fournir le nom de votre
              opérateur télécom, le modèle de votre téléphone portable et un
              numéro de portable valide afin de pouvoir fournir les instructions
              d’achat directement à travers votre téléphone mobile.
            </p>
            <p>
              Nous conservons les détails de vos paiements, ainsi que les
              détails des achats que vous effectuez. Le détail des transactions
              est conservé soit dans nos systèmes soit chez le prestataire
              externe. Cette conservation est effectuée à des fins internes,
              notamment pour la comptabilité, la conformité et à des fins
              juridiques, conformément au paragraphe 5 de la présente Charte.
            </p>

            <p><strong>1.3 Contacts</strong></p>
            <p>
              Afin de donner suite aux demandes que vous pourriez effectuer
              auprès de notre Service Client et de confirmer les informations
              vous concernant, nous pourrons utiliser vos nom, prénom, adresse
              de courrier électronique et numéro de téléphone.
            </p>

            <h2>
              2. Comment protégeons-nous vos données à caractère personnel ?
            </h2>
            <p>
              Nous avons mis en place des mesures de sécurité techniques et
              organisationnelles en vue de garantir la sécurité, l’intégrité et
              la confidentialité de toutes vos données à caractère personnel,
              afin d’empêcher que celles-ci soient déformées, endommagées ou que
              des tiers non autorisés y aient accès. Nous assurons un niveau de
              sécurité approprié, compte tenu de l'état des connaissances, des
              coûts de mise en œuvre et de la nature, de la portée, du contexte
              et des finalités du traitement ainsi que des risques et de leur
              probabilité.
            </p>
            <p>
              Toutefois, il est précisé qu’aucune mesure de sécurité n’étant
              infaillible, nous ne sommes pas en mesure de garantir une sécurité
              absolue à vos données à caractère personnel.
            </p>

            <h2>
              3. Dans quels cas partageons-nous vos données à caractère
              personnel ?
            </h2>
            <p>
              <strong
                >3.1 Partage de vos données personnelles avec des sociétés
                tierces</strong
              >
            </p>
            <p>
              Lors de votre navigation sur le Site, vos données à caractère
              personnel peuvent être transmises à des prestataires extérieurs.
              Ces tiers assurent un service pour notre compte et en notre nom
              dans le but de permettre le bon fonctionnement des paiements par
              carte de crédit et autres Services.
            </p>

            <p>
              Les données à caractère personnel sont susceptibles d’être
              transférées vers des pays situés hors de l’Union Européenne (tels
              que Etats-Unis, Suisse) dans le but de stocker vos données sur un
              service cloud et d’améliorer la stabilité du Site.
            </p>

            <p>
              Conformément au RGPD, tous les transferts de données à caractère
              personnel vers un pays situé hors de l’Union Européenne et/ou
              n’offrant pas un niveau de protection considéré comme suffisant
              par la Commission européenne ont fait l’objet de conventions de
              flux transfrontière conformes aux clauses contractuelles types
              édictées par la Commission européenne.
            </p>

            <p>
              D’autres transferts de données à caractère personnel vers les
              Etats-Unis sont régis par le E.U. – U.S. PRIVACY SHIELD (Bouclier
              de protection des Données Union Européenne-Etats-Unis) :
              <a href="https://www.cnil.fr/fr/le-privacy-shield"
                >cliquez ici pour plus d’informations</a
              >.
            </p>

            <p>
              Sauf dans le cas où un tiers vous demande d’accepter une charte de
              confidentialité et des conditions d’utilisations qui lui sont
              propres, les sociétés tierces ayant reçu communication de vos
              données à caractère personnel se sont engagées à traiter vos
              données à caractère personnel uniquement pour la mise en œuvre de
              nos Services.
            </p>

            <p>
              Nous ne partagerons jamais, sans avoir obtenu votre consentement
              préalable, vos données à caractère personnel avec des sociétés
              tierces à des fins marketings et/ou commerciales.
            </p>

            <p><strong>3.2 Partage avec les autorités</strong></p>

            <p>
              Nous pouvons être amené à divulguer vos données à caractère
              personnel aux autorités administrative ou judiciaire lorsque leur
              divulgation est nécessaire à l'identification, l'interpellation ou
              la poursuite en justice de tout individu susceptible de porter
              préjudice à nos droits, de tout autre utilisateur ou d’un tiers.
              Nous pouvons enfin être légalement tenu de divulguer vos données à
              caractère personnel et ne pouvons dans ce cas nous y opposer.
            </p>

            <h2>4. Cookies : comment les utilisons-nous ?</h2>
            <p><strong>4.1 Qu’est-ce qu’un cookie ?</strong></p>
            <p>
              Un cookie est un fichier texte susceptible d’être déposé dans un
              terminal lors de la consultation d’un service en ligne avec un
              logiciel de navigation. Un fichier cookie permet notamment à son
              émetteur, pendant sa durée de validité, de reconnaître le terminal
              concerné à chaque fois que ce terminal accède à un contenu
              numérique comportant des cookies du même émetteur.
            </p>
            <p>
              En tout état de cause, les cookies déposés sur votre terminal de
              navigation avec votre accord sont détruits 13 mois après leur
              dépôt sur votre terminal.
            </p>

            <p>
              <strong
                >4.2 A quoi servent les cookies émis sur notre Site ?</strong
              >
            </p>
            <p>Les cookies que nous émettons nous permettent :</p>
            <ul>
              <li>
                d'établir des statistiques et volumes de fréquentation et
                d'utilisation des divers éléments composant notre Site
                (rubriques et contenus visités, parcours), nous permettant
                d'améliorer l'intérêt et l'ergonomie du Site et, le cas échéant,
                de nos produits et services ;
              </li>
              <li>
                d'adapter la présentation de notre Site aux préférences
                d'affichage de votre terminal (langue utilisée, résolution
                d'affichage, système d'exploitation utilisé, etc.) lors de vos
                visites sur notre Site, selon les matériels et les logiciels de
                visualisation ou de lecture que votre terminal comporte ;
              </li>
            </ul>
            <p>
              Lors de votre navigation sur le Site, des cookies des réseaux
              sociaux peuvent être générés notamment par l’intermédiaire des
              boutons de partage qui collectent des données à caractère
              personnel.
            </p>
            <p>
              Lors de votre première visite sur le Site, un bandeau cookies
              apparaîtra en page d’accueil. Un lien cliquable permet d’en savoir
              plus sur la finalité et le fonctionnement des cookies et renvoie
              vers la présente Charte. La poursuite de la navigation sur une
              autre page du site ou la sélection d’un élément du Site (notamment
              : image, texte, lien, etc.) matérialise votre acceptation au dépôt
              des cookies visés sur votre ordinateur.
            </p>

            <p>
              <strong
                >4.3 Comment pouvez-vous contrôler les cookies utilisés
                ?</strong
              >
            </p>
            <p>
              Vous pouvez à tout moment configurer votre logiciel de navigation
              de manière à ce que des cookies soient enregistrés dans votre
              terminal ou, au contraire, qu'ils soient rejetés (soit
              systématiquement, soit selon leur émetteur). Vous pouvez également
              configurer votre logiciel de navigation de manière à ce que
              l'acceptation ou le refus des cookies vous soit proposé
              ponctuellement, avant qu'un cookie puisse être enregistré dans
              votre terminal.
            </p>
            <p>
              <strong>Attention :</strong> tout paramétrage est susceptible de
              modifier votre navigation sur Internet et vos conditions d'accès à
              certains services nécessitant l'utilisation de cookies. Nous
              déclinons toute responsabilité s’agissant des conséquences liées
              au fonctionnement dégradé de nos services résultant de
              l'impossibilité d'enregistrer ou de consulter les cookies
              nécessaires à leur fonctionnement et que vous auriez refusés ou
              supprimés. Tel serait le cas si vous tentiez d'accéder à nos
              contenus ou services qui nécessitent de vous identifier. Tel
              serait également le cas lorsque nous (ou nos prestataires) ne
              pourrions pas reconnaître, à des fins de compatibilité technique,
              le type de navigateur utilisé par votre terminal, ses paramètres
              de langue et d'affichage ou le pays depuis lequel votre terminal
              semble connecté à Internet.
            </p>

            <p>
              <strong
                >4.4 Comment configurer votre logiciel de navigation ?</strong
              >
            </p>
            <p>
              Pour la gestion des cookies et de vos choix, la configuration de
              chaque navigateur est différente. Elle est décrite dans le menu
              d'aide de votre navigateur, qui vous permettra de savoir de quelle
              manière modifier vos souhaits en matière de cookies. Vous
              trouverez ci-dessous des informations concernant les principaux
              navigateurs.
            </p>

            <p><strong>Internet Explorer / Edge</strong></p>
            <ul>
              <li>
                Dans Internet Explorer, cliquez sur le bouton Outils, puis sur
                Options Internet.
              </li>
              <li>
                Sous l'onglet Général, sous Historique de navigation, cliquez
                sur Paramètres.
              </li>
              <li>Cliquez sur le bouton Afficher les fichiers.</li>
            </ul>

            <p><strong>Firefox</strong></p>
            <ul>
              <li>
                Allez dans l'onglet Outils du navigateur puis sélectionnez le
                menu Options
              </li>
              <li>
                Dans la fenêtre qui s'affiche, choisissez Vie privée et cliquez
                sur Affichez les cookies
              </li>
            </ul>

            <p><strong>Safari</strong></p>
            <ul>
              <li>
                Accédez aux Paramètres via le menu du navigateur (Safari >
                Préférences)
              </li>
              <li>Cliquez sur Confidentialité.</li>
            </ul>

            <p><strong>Google Chrome</strong></p>
            <ul>
              <li>
                Accédez aux Paramètres via le bouton à droite de la barre d’URL
                ou via le menu du navigateur (Chrome > Préférences).
              </li>
              <li>Sélectionnez Paramètres Avancées</li>
              <li>Cliquez sur Paramètres du contenu puis sur Cookies.</li>
            </ul>

            <p>
              Pour obtenir plus d’information sur les cookies, vous pouvez
              consulter
              <a
                href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs"
                target="_blank"
                >le site internet de la CNIL</a
              >.
            </p>

            <h2>5. Quels sont vos droits ?</h2>
            <p>
              Vous êtes seuls à nous avoir communiqué les données en notre
              possession, par l’intermédiaire du Site. Vous disposez de droits
              sur vos données à caractère personnel. Conformément à la
              réglementation en matière de protection des données à caractère
              personnel, notamment les articles 15 à 22 du RGPD, et après avoir
              justifié de votre identité, vous avez le droit de nous demander
              l'accès aux données à caractère personnel vous concernant, la
              rectification ou l'effacement de celles-ci.
            </p>
            <p>
              En outre, dans les limites posées par la loi, vous disposez
              également du droit de vous opposer au traitement, de le limiter,
              de décider du sort post-mortem de vos données, de retirer votre
              consentement à tout moment et du droit à la portabilité des
              données à caractère personnel fournies.
            </p>
            <p>
              Vous pouvez contactez nos Services afin d’exercer vos droits à
              l’adresse électronique suivante : hello@lhight.fr ou à l’adresse
              postale suivante : Stanislas Kerné 45 rue Boussingault 75013 Paris
              , en joignant à votre demande une copie d’un titre d’identité.
            </p>

            <h2>6. Pouvons-nous modifier la Charte ?</h2>
            <p>
              Nous nous réservons le droit de modifier la Charte à tout moment.
              Il vous est donc recommandé de la consulter régulièrement. En cas
              de modification, nous publierons ces changements sur cette page et
              aux endroits que nous jugerons appropriés en fonction de l’objet
              et de l’importance des changements apportés.
            </p>
            <p>
              Votre utilisation du Site après toute modification signifie que
              vous acceptez ces modifications. Si vous n'acceptez pas certaines
              modifications substantielles apportées à la présente Charte, vous
              devez arrêter d'utiliser le Site.
            </p>

            <h2>7. Délégué à la Protection des Données et contact</h2>
            <p>
              Pour toute question concernant vos données à caractère personnel
              ou si vous souhaitez supprimer votre Compte, merci de nous
              contacter à l’adresse postale suivante : Stanislas Kerné, 45 rue
              Boussingault 75013 Paris (en indiquant "Vie Privée - Protection
              des Données"), ou par email à hello@lhight.fr.
            </p>
            <p>
              Nous vous rappelons que vous pouvez contacter la CNIL directement
              sur le
              <a
                href="https://www.cnil.fr/fr/agir%20"
                title="Site CNIL"
                target="_blank"
                >site internet de la CNIL</a
              >
              ou par courrier à l’adresse suivante : Commission Nationale de
              l'Informatique et des Libertés (CNIL), 3 Place de Fontenoy - TSA
              80715, 75334 PARIS CEDEX 07.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "confidentiality",
};
</script>
