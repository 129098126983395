<template>
  <Confidentiality />
</template>

<script>
import Confidentiality from "@/components/confidentiality/Confidentiality.vue";

export default {
  name: "confidentiality",
  components: {
    Confidentiality,
  },
};
</script>
