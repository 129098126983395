<template>
  <section class="lh-WrapperSingle">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--full">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link">Conditions générales de vente.</a>
            </li>
          </ul>
          <div class="lh-Text">
            <h1>Conditions générales de vente.</h1>

            <p class="lh-Form-date">
              Date de dernière mise à jour : 24/09/2021
            </p>

            <h2>Article 1 – MENTION LÉGALES</h2>
            <p>
              Le présent site, accessible à l’URL www.lhight.fr (le « Site »),
              est édité par :
            </p>
            <p>
              LHight en cours d'inscription au R.C.S. du Havre et dont le siège
              social est situé au 18 rue Fléchier 76600 Le Havre, représenté(e)
              par Stanislas Kerné dument habilité(e),(Ci-après désigné l’«
              Exploitant »).
            </p>
            <p>
              Le numéro individuel TVA de l’Exploitant est : en cours
              d'acquisition.
            </p>
            <p>
              Le Site est hébergé par la société OVH, situé 2 rue Kellermann -
              59100 Roubaix - France.
            </p>
            <p>Le Directeur de la publication du Site est Stanislas Kerné.</p>
            <p>
              L’Exploitant peut être joint au numéro de téléphone suivant
              +33763436798 et à l’adresse mail suivante hello@lhight.fr.
            </p>

            <h2>
              Article 2 – DISPOSITIONS GÉNÉRALES RELATIVES AUX PRÉSENTES
              CONDITIONS GÉNÉRALES
            </h2>
            <p>
              Les conditions générales de Vente (les « Conditions Générales de
              Vente », ou les « CGV ») sont applicables exclusivement à la vente
              en ligne des produits proposés par l’Exploitant sur le Site
              internet.
            </p>
            <p>
              Les CGV sont mises à la disposition des clients sur le Site où
              elles sont directement consultables et peuvent également lui être
              communiquées sur simple demande par tout moyen.
            </p>
            <p>
              Les CGV sont opposables au client qui reconnaît, en cochant une
              case ou en cliquant sur le bouton prévu à cet effet, en avoir eu
              connaissance et les avoir acceptées avant de passer commande. La
              validation de la commande par sa confirmation vaut adhésion par
              l'acheteur aux CGV en vigueur au jour de la commande dont la
              conservation et la reproduction sont assurées par l’Exploitant.
            </p>

            <h2>Article 3 – DESCRIPTION DES PRODUITS</h2>
            <p>
              Le Site est un site de vente en ligne de produits de décoration
              (ci-après le(s) « Produit(s)») ouvert à toute personne physique ou
              morale utilisant le Site (le « Client »).
            </p>
            <p>
              Les Produits présentés sur le Site font chacun l'objet d'un
              descriptif mentionnant leurs caractéristiques essentielles. Les
              photographies illustrant, le cas échéant, les produits ne
              constituent pas un document contractuel. La notice d'utilisation
              du Produit, si c'est un élément essentiel, figure sur le Site ou
              est au plus tard adressée à la livraison. Les Produits sont
              conformes aux prescriptions du droit français en vigueur.
            </p>
            <p>
              Le Client demeure responsable des modalités et des conséquences de
              son accès au Site notamment par l’Internet. Cet accès peut
              impliquer le paiement de frais à des prestataires techniques tels
              que notamment des fournisseurs d’accès à l’Internet, lesquels
              demeurent à sa charge. En outre, le Client devra fournir et être
              entièrement responsable des équipements nécessaires afin de se
              connecter au Site.
            </p>
            <p>
              Le Client reconnait avoir vérifié que la configuration
              informatique qu’il utilise est sécurisée et en état de
              fonctionnement.
            </p>

            <h2>Article 4 – COMMANDES</h2>
            <p>
              L’Exploitant s’efforce de garantir une disponibilité optimale de
              ses Produits. Les offres de Produits sont valables dans la limite
              des stocks disponibles.
            </p>
            <p>
              Si en dépit des meilleurs efforts de l’Exploitant, un Produit
              s’avérerait indisponible postérieurement à la commande du Client,
              l’Exploitant en informera le Client par email, dans les meilleurs
              délais et le Client aura le choix entre :
            </p>
            <p>
              la livraison d’un Produit d’une qualité et d’un prix équivalent à
              celui initialement commandé, ou le remboursement du prix du
              Produit commandé au plus tard dans les trente (30) jours du
              paiement des sommes déjà versées.
            </p>
            <p>
              Il est convenu qu’en dehors du remboursement du prix du Produit
              indisponible, si cette option est demandée par le Client,
              l’Exploitant n'est tenu à aucune indemnité d'annulation, sauf si
              l'inexécution du contrat lui est personnellement imputable.
            </p>
            <p>
              A l’exception de toute mention contraire figurant dans ces
              Conditions Générales et sans préjudice du droit de rétractation
              prévu par la loi applicable, les commandes du Client sont fermes
              et définitives.
            </p>
            <p>
              Au moment de passer une commande, le Client doit sélectionner les
              Produits choisis, les ajouter à son panier en indiquant les
              Produits sélectionnés et les quantités souhaitées. Le Client a la
              possibilité de vérifier le détail de sa commande et son prix
              total, et de revenir aux pages précédentes pour éventuellement
              corriger le contenu de son panier, avant de le valider.
            </p>
            <p>
              Le Client s’engage à lire les Conditions Générales de Vente alors
              en vigueur avant de les accepter et de confirmer les modalités et
              les éventuels frais de livraison et de rétractation préalable au
              paiement de sa commande. La confirmation de la commande entraîne
              acceptation des CGV et forme le contrat.
            </p>
            <p>
              Une copie des présentes Conditions Générales telles qu’acceptées
              par le Client sera envoyée au Client par e-mail au moment de la
              confirmation de sa Commande afin que ce dernier puisse s’y
              reporter.
            </p>
            <p>
              Les informations contractuelles relatives à la commande (dont
              notamment le n° de la commande) feront l'objet d'une confirmation
              par voie d'e-mail en temps utile et au plus tard au moment de la
              livraison. L’Exploitant conseille vivement au Client d'imprimer
              et/ou d'archiver sur un support fiable et durable cette
              confirmation de commande à titre de preuve. Une facture numérique
              est mise à la disposition du Client dans l'espace « mon compte ».
              L’Exploitant conseille également au Client d'imprimer et/ou
              d'archiver sur un support fiable et durable cette facture à titre
              de preuve.
            </p>
            <p>
              Tout email qui sera adressé au Client dans le cadre d’une commande
              le sera à l’adresse email que le Client utilise pour s’identifier
              dans son espace client.
            </p>
            <p>
              L’Exploitant se réserve le droit de ne pas valider la commande du
              Client pour tout motif légitime, notamment dans l’hypothèse où :
            </p>
            <p>
              Le Client ne respecterait pas les Conditions Générales en vigueur
              lors de sa commande ;
            </p>
            <p>
              L’historique de commandes du Client montre que des sommes restent
              dues au titre de précédentes commandes ;
            </p>
            <p>
              L’une des précédentes commandes du Client fait l’objet d’un litige
              en cours de traitement ;
            </p>
            <p>
              Le Client n’a pas répondu à une demande de confirmation de sa
              commande que l’Exploitant lui a fait parvenir.
            </p>
            <p>
              L’Exploitant archive les contrats de vente de Produits
              conformément à la législation applicable. En formulant une demande
              à l’adresse suivante contact@letter-in.com, l’Exploitant remettra
              au Client une copie du contrat objet de la demande.
            </p>
            <p>
              Toute modification de commande par le Client après confirmation de
              sa commande est soumise à l'accord de l’Exploitant.
            </p>
            <p>
              Les informations communiquées par le Client lors de la passation
              de la commande (notamment nom et adresse de livraison) engagent
              celui-ci. Ainsi, la responsabilité de l’Exploitant ne saurait en
              aucune manière être recherchée dans l'éventualité où une erreur
              lors de la passation de la commande empêcherait ou retarderait la
              livraison/la délivrance.
            </p>
            <p>
              Le Client déclare avoir la pleine capacité juridique lui
              permettant de s'engager au titre des présentes Conditions
              Générales.
            </p>
            <p>
              L'inscription est ouverte aux majeurs capables et aux mineurs à
              conditions que ceux-ci interviennent sous la surveillance du
              parent ou tuteur détenant l'autorité parentale. En aucun cas,
              l'inscription n'est autorisée pour le compte de tiers à moins
              d'être valablement habilité à la représenter (personne morale par
              exemple). L'inscription est strictement personnelle à chaque
              Client.
            </p>
            <p>
              En cas de manquement par le Client à l'une des dispositions des
              présentes, l’Exploitant se réserve le droit de résilier sans
              préavis le compte dudit Client.
            </p>

            <h2>Article 5 – MODALITES DE PAIEMENT ET SÉCURISATION</h2>
            <p>
              Le Client reconnaît expressément que toute commande effectuée sur
              le Site est une commande avec obligation de paiement, qui
              nécessite le paiement d’un prix contre la fourniture du Produit
              commandé.
            </p>
            <p>
              En toute hypothèse, l’Exploitant se réserve le droit de contrôler
              la validité du règlement, avant l'expédition de la commande, par
              tous les moyens nécessaires.
            </p>
            <p>
              L’Exploitant utilise les solutions de paiement en ligne Paypal.
            </p>
            <p>
              Les commandes peuvent être payées en ayant recours à un paiement
              par portefeuille électronique (Paypal). Le Client possède déjà un
              compte sur le portefeuille électronique utilisé par l’Exploitant.
              Le Client peut utiliser ce compte et régler sa commande en toute
              sécurité sans communiquer ses coordonnées bancaires.
            </p>
            <p>
              Le cas échéant, la commande validée par le Client ne sera
              considérée comme effective que lorsque le centre de paiement
              bancaire sécurisé aura donné son accord sur la transaction.
            </p>
            <p>
              Dans le cadre des procédures de contrôle, l’Exploitant pourra
              avoir à demander au Client toutes les pièces nécessaires à la
              finalisation de sa commande. Ces pièces ne seront pas utilisées à
              d’autres fins que celles-ci.
            </p>

            <h2>Article 6 – PAIEMENT DU PRIX</h2>
            <p>
              Le prix des Produits en vigueur lors de la commande est indiqué en
              euros toutes taxes comprises (TTC) hors frais de livraison et de
              transport. En cas de promotion, l’Exploitant s'engage à appliquer
              le prix promotionnel à toute commande passée durant la période de
              la publicité faite pour la promotion.
            </p>
            <p>
              Le prix est payable en euros (€) exclusivement. Le prix est
              exigible en totalité après confirmation de la commande. Les prix
              proposés comprennent les rabais et ristournes que l’Exploitant
              serait amené à octroyer.
            </p>
            <p>
              Si des frais de livraison ou de transport s’appliquent, ils seront
              ajoutés au prix des Produits et indiqués de manière distincte
              avant la validation de la commande par le Client. Le montant total
              dû par le Client et son détail sont indiqués sur la page de
              confirmation de commande.
            </p>

            <h2>Article 7 – FORMATION DU CONTRAT</h2>
            <p>
              Le contrat entre l’Exploitant et le Client est formé au moment de
              l'envoi par le Client de la confirmation de sa commande.
            </p>
            <p>
              L'archivage des communications, des bons de commande et des
              factures est assuré par l’Exploitant sur un support fiable et
              durable de manière à constituer une copie fidèle et durable. Ces
              communications, bons de commande et factures peuvent être produits
              à titre de preuve du contrat. Sauf preuve contraire, les données
              enregistrées par l’Exploitant sur Internet ou par téléphone
              constituent la preuve de l'ensemble des transactions passées entre
              l’Exploitant et ses Clients.
            </p>
            <p>
              La commande peut être résolue par le Client par lettre recommandée
              avec demande d'avis de réception ou par un écrit sur un autre
              support durable en cas :
            </p>
            <ul>
              <li>
                de livraison d'un Produit non conforme aux caractéristiques
                déclarées du Produit ;
              </li>
              <li>
                de livraison dépassant la date limite fixée dans le bon de
                commande ou, à défaut d'une telle date, dans les trente (30)
                jours suivant la conclusion du contrat, après que l’Exploitant
                ait été enjoint, selon les mêmes modalités et sans résultat,
                d'effectuer la livraison dans un délai supplémentaire
                raisonnable;
              </li>
              <li>
                de hausse du prix qui n'est pas justifiée par une modification
                technique du produit, et/ou imposée par les pouvoirs publics.
              </li>
            </ul>
            <p>
              Dans tous ces cas, le Client peut exiger le remboursement de
              l'acompte versé majoré des intérêts calculés au taux légal à
              partir de la date d'encaissement de l'acompte.
            </p>
            <p>La commande peut être résolue par l’Exploitant en cas :</p>
            <ul>
              <li>de refus de l'acheteur de prendre la livraison;</li>
              <li>
                de non-paiement du prix (ou du solde du prix) au moment de la
                livraison.
              </li>
            </ul>

            <h2>Article 8 – RÉSERVE DE PROPRIÉTÉ</h2>
            <p>
              L’Exploitant reste propriétaire exclusif des Produits commandés
              sur le Site jusqu’à encaissement de l’intégralité du prix, en ce
              compris les éventuels frais de port.
            </p>

            <h2>Article 9 – EXPÉDITION ET LIVRAISON</h2>
            <p>
              Les offres de vente en ligne présentées sur le site sont réservées
              aux consommateurs résidant en France ou, le cas échéant, dans un
              pays membre de l'Union européenne.
            </p>
            <p>
              La livraison s'entend du transfert au Client de la possession
              physique ou du contrôle du Produit.
            </p>
            <p>
              L’Exploitant se charge lui-mêne de la livraison du produit dans
              toute la ville du Havre et de Sainte-Adresse dans un délais de
              deux (2) jours ouvrés au Havre
            </p>
            <p>
              Les frais d’envoi sont ceux précisés lors de la finalisation de la
              commande et sont acceptés par la validation de la commande.
            </p>
            <p>
              L’Exploitant s'engage, conformément à la date limite de livraison
              indiquée sur le Site pour chacun des Produits, à livrer les
              Produits dans un délai maximum de cinq (5) jours après réception
              de commande.
            </p>
            <p>
              Les délais de livraison sont annoncés en jours ouvrés sur le Site
              lors de la commande. Ces délais incluent la préparation et
              l’expédition de la commande ainsi que le délai prévu par le
              transporteur.
            </p>
            <p>
              L’Exploitant s’engage à expédier les Produits conformément aux
              délais annoncés sur chaque fiche Produit, sous réserve que le
              paiement de la commande n’ait pas été préalablement refusé.
            </p>
            <p>
              Cependant, si un ou plusieurs Produits ne pouvaient être livrés
              dans le délai initialement annoncé, l’Exploitant adressera un
              email indiquant au Client la nouvelle date de livraison.
            </p>
            <p>
              Les Produits seront livrés à l'adresse indiquée par le Client lors
              de sa commande. Il lui appartient donc de vérifier que cette
              adresse ne contient pas d’erreur. La responsabilité de
              l’Exploitant ne pourra être engagée si l’adresse communiquée par
              le Client est erronée, empêchant ou retardant ainsi la livraison.
            </p>
            <p>
              À la livraison, il pourra être demandé de signer un bon de
              réception.
            </p>
            <p>
              Aucune livraison ne sera effectuée à une boîte postale ou à une
              addresse en dehors du Havre et de Sainte-Adresse
            </p>
            <p>
              À la livraison, il appartient au Client de vérifier que les
              Produits livrés sont conformes à sa commande et que le colis est
              scellé et non endommagé. Si ce n’est pas le cas, le Client doit
              impérativement l’indiquer sur le bordereau de livraison. Aucune
              réclamation sur la quantité ou l’état du Produit ne sera admise si
              la réclamation n’a pas été portée sur le bordereau de livraison.
            </p>

            <h2>Article 10 – DROIT DE RÉTRACTATION</h2>
            <p>
              Si un Produit livré ne donne pas entière satisfaction au Client,
              ce dernier pourra le retourner à l’Exploitant. Le Client disposera
              de quatorze (14) jours pour le faire à compter de la date de
              réception de la commande.
            </p>
            <p>
              Conformément à l’article L.221-21 du Code de la consommation et
              afin de mettre en œuvre ce droit de rétractation dans les
              conditions des articles L. 221-18 et suivants du Code de la
              consommation, le Client est invité à envoyer un email à l’adresse
              <a
                href="mailto:hello@lhight.fr?subject=Hello&nbsp;LHight&amp;body=Hello&nbsp;Stan,"
                >hello@lhight.fr</a
              >
              en précisant son nom, la motivation de la rétractation et le
              numéro de commande.
            </p>
            <p>
              L’Exploitant adressera un accusé de réception de la demande de
              rétractation du Client par e-mail.
            </p>
            <p>
              Le cas échéant, le Client peut exercer son droit de rétractation
              en notifiant les informations suivantes à l’Exploitant :
            </p>
            <ul>
              <li>
                nom, adresse géographique, numéro de téléphone et adresse
                électronique;
              </li>
              <li>
                décision de rétractation au moyen d'une déclaration dénuée
                d'ambiguïté (par exemple, lettre envoyée par la poste,
                télécopie).
              </li>
              <li>
                Le numéro de commande correspondant aux produits que le Client
                souhaite retourner
              </li>
            </ul>
            <p>Les frais de retour sont à la charge du Client.</p>
            <p>
              Les exceptions de l’article L.221-28 du Code de la Consommation
              s’appliquent et font obstacle à l’exercice du droit de
              rétractation, notamment si la commande consiste en un contrat :
            </p>
            <ul>
              <li>
                de fourniture de services pleinement exécutés avant la fin du
                délai de rétractation et dont l'exécution a commencé après
                accord préalable exprès du consommateur et renoncement exprès à
                son droit de rétractation ;
              </li>
              <li>
                de fourniture de biens ou de services dont le prix dépend de
                fluctuations sur le marché financier échappant au contrôle du
                professionnel et susceptibles de se produire pendant le délai de
                rétractation ;
              </li>
              <li>
                de fourniture de biens confectionnés selon les spécifications du
                consommateur ou nettement personnalisés ;
              </li>
              <li>
                de fourniture de biens susceptibles de se détériorer ou de se
                périmer rapidement ;
              </li>
              <li>
                de fourniture de biens qui ont été descellés par le consommateur
                après la livraison et qui ne peuvent être renvoyés pour des
                raisons d'hygiène ou de protection de la santé;
              </li>
              <li>
                de fourniture de biens qui, après avoir été livrés et de par
                leur nature, sont mélangés de manière indissociable avec
                d'autres articles ;
              </li>
              <li>
                de fourniture de boissons alcoolisées dont la livraison est
                différée au-delà de trente (30) jours et dont la valeur convenue
                à la conclusion du contrat dépend de fluctuations sur le marché
                échappant au contrôle du professionnel ;
              </li>
              <li>
                de travaux d'entretien ou de réparation à réaliser en urgence au
                domicile du consommateur et expressément sollicités par lui,
                dans la limite des pièces de rechange et travaux strictement
                nécessaires pour répondre à l'urgence ;
              </li>
              <li>
                de fourniture d'enregistrements audio ou vidéo ou de logiciels
                informatiques lorsqu'ils ont été descellés par le consommateur
                après la livraison ;
              </li>
              <li>
                de fourniture d'un journal, d'un périodique ou d'un magazine,
                sauf pour les contrats d'abonnement à ces publications ;
              </li>
              <li>conclu lors d'une enchère publique ;</li>
              <li>
                de prestation de services d'hébergement, autres que
                d'hébergement résidentiel, de services de transport de biens, de
                locations de voitures, de restauration ou d'activités de loisirs
                qui doivent être fournis à une date ou à une période déterminée
                ;
              </li>
              <li>
                de fourniture d'un contenu numérique non fourni sur un support
                matériel dont l'exécution a commencé après accord préalable
                exprès du consommateur et renoncement exprès à son droit de
                rétractation.
              </li>
            </ul>
            <p>
              Le Produit retourné doit l’être dans son emballage d'origine, en
              parfait état, propre à sa revente, non utilisé et avec l’ensemble
              des accessoires éventuels.
            </p>
            <p>
              Outre le Produit retourné, le colis de retour doit également
              contenir un courrier précisant les coordonnées exactes (nom,
              prénom, adresse) et complètes du Client ainsi que le numéro de
              commande, et la facture originale d’achat.
            </p>
            <p>
              L’Exploitant remboursera au Client le montant du Produit dans un
              délai de quatorze (14) jours à compter de la réception du Produit
              et de l’ensemble des éléments permettant de mettre en œuvre le
              remboursement du Client. Ce remboursement sera effectué par le
              même moyen de paiement que celui employé pour le Client. A ce
              titre, le Client ayant réglé sa commande sous forme d'avoirs /
              bons cadeau pourront être remboursés par avoirs / bons cadeau
              selon la volonté de l’Exploitant.
            </p>
            <p>
              En acceptant les présentes Conditions Générales de Vente, le
              Client reconnaît expressément avoir été informé des modalités de
              rétractation.
            </p>

            <h2>Article 11 – SERVICE CLIENTS</h2>
            <p>Le Client peut contacter l’Exploitant :</p>
            <ul>
              <li>
                au numéro suivant 07 63 43 67 98 au jour et heures d’ouverture
                suivants : du lundi au vendredi de 9h00 à 18h00.
              </li>
              <li>
                par email en vous adressant à hello@lhight.fr en indiquant son
                nom, numéro de téléphone, l’objet de sa demande et le numéro de
                la commande concernée.
              </li>
            </ul>
            <h2>
              Article 12 – PROPRIÉTÉ INTELLECTUELLE ET LICENCE D’UTILISATION DU
              SITE
            </h2>
            <p>
              L’Exploitant est seul titulaire de tous les éléments présent sur
              le Site, notamment et sans limitation, tous textes, fichiers,
              images animées ou non, photographies, vidéos, logos, dessins,
              modèles, logiciels, marques, identité visuelle, base de données,
              structure du Site et tous autres éléments de propriété
              intellectuelle et autres données ou informations (ci-après, les «
              Éléments ») qui sont protégés par les lois et règlements français
              et internationaux relatifs notamment à la propriété
              intellectuelle.
            </p>
            <p>
              En conséquence, aucun des Éléments du Site ne pourra en tout ou
              partie être modifié, reproduit, copié, dupliqué, vendu, revendu,
              transmis, publié, communiqué, distribué, diffusé, représenté,
              stocké, utilisé, loué ou exploité de toute autre manière, à titre
              gratuit ou onéreux, par un Client ou par un tiers, quel que soient
              les moyens et/ou les supports utilisés, qu’ils soient connus ou
              inconnus à ce jour, sans l’autorisation préalable exprès et écrite
              de l’Exploitant au cas par cas, et le Client est seul responsable
              de toute utilisation et/ou exploitation non autorisée.
            </p>
            <p>
              L’Exploitant se réserve la possibilité de saisir toutes voies de
              droit à l’encontre des personnes qui n’auraient pas respecté les
              interdictions contenues dans le présent article.
            </p>

            <h2>ARTICLE 13 – RESPONSABILITÉ ET GARANTIE</h2>
            <p>
              L’Exploitant ne saurait être tenu pour responsable de
              l'inexécution du contrat du fait du Client ou en raison d'un
              événement qualifié de force majeure par les tribunaux compétents
              ou encore du fait imprévisible et insurmontable de tout tiers aux
              présentes.
            </p>
            <p>
              Le Client reconnaît que les caractéristiques et les contraintes
              d'Internet ne permettent pas de garantir la sécurité, la
              disponibilité et l'intégrité des transmissions de données sur
              Internet. Ainsi, l’Exploitant ne garantit pas que le Site et ses
              services fonctionneront sans interruption ni erreur de
              fonctionnement. En particulier, leur exploitation pourra être
              momentanément interrompue pour cause de maintenance, de mises à
              jour ou d'améliorations techniques, ou pour en faire évoluer le
              contenu et/ou leur présentation.
            </p>
            <p>
              L’Exploitant ne peut être tenu pour responsable de l'utilisation
              qui serait faite du Site et de ses services par les Clients en
              violation des présentes Conditions Générales et des dommages
              directs ou indirects que cette utilisation pourrait causer à un
              Client ou à un tiers. En particulier, l’Exploitant ne peut être
              tenu pour responsable des fausses déclarations faites par un
              Client et de son comportement vis-à-vis des tiers. Dans le cas où
              la responsabilité de l’Exploitant serait recherchée à raison d'un
              tel comportement d’un de ses Clients, ce dernier s'engage à
              garantir l’Exploitant contre toute condamnation prononcée à son
              encontre ainsi qu’à rembourser l’Exploitant de l’ensemble des
              frais, notamment les honoraires d’avocats, engagés pour sa
              défense.
            </p>
            <p>
              Indépendamment de toute garantie contractuelle additionnelle
              (garantie commerciale) qui pourrait être consentie, les Produits
              bénéficient de la garantie légale de conformité prévue aux
              articles L. 217-4 et suivants du Code de la consommation
              (notamment L. 217-4 à L. 217-14 du Code de la consommation), et de
              la garantie des vices cachés prévue aux articles 1641 à 1649 du
              Code civil.
            </p>
            <p>
              Lorsque vous agissez dans le cadre de la garantie légale de
              conformité :
            </p>
            <ul>
              <li>
                vous bénéficiez d'un délai de deux (2) ans à compter de la
                délivrance du bien pour agir :
              </li>
              <li>
                vous pouvez choisir entre le remboursement ou le remplacement du
                bien, sous réserve des conditions de coût prévues par l'article
                L. 217-9 du Code de la consommation ;
              </li>
              <li>
                vous êtes dispensé de rapporter la preuve de l'existence du
                défaut de conformité du bien durant les vingt-quatre (24) mois
                suivant la délivrance du bien (sauf biens d’occasion).
              </li>
            </ul>
            <p>
              Vous pouvez décider de mettre en œuvre la garantie contre les
              défauts cachés de la chose vendue au sens de l'article 1641 du
              Code civil. Dans cette hypothèse, vous pouvez choisir entre la
              résolution de la vente ou une réduction du prix de vente
              conformément à l'article 1644 du Code civil.
            </p>
            <p>
              Reproduction des articles L. 217-4, L. 217-5, L. 217-7, L. 217-9
              et L. 217-12 du Code de la consommation, de l'article 1641, 1644
              et du premier alinéa de l'article 1648 du Code civil, tels qu’en
              vigueur à la date des présentes Conditions Générales de Vente :
            </p>
            <p>Art. L.217-4 du code de la consommation :</p>
            <p>
              « Le vendeur livre un bien conforme au contrat et répond des
              défauts de conformité existant lors de la délivrance. Il répond
              également des défauts de conformité résultant de l'emballage, des
              instructions de montage ou de l'installation lorsque celle-ci a
              été mise à sa charge par le contrat ou a été réalisée sous sa
              responsabilité. »
            </p>
            <p>Art. L.217-5 du code de la consommation :</p>
            <p>« Le bien est conforme au contrat :</p>
            <p>
              1° S'il est propre à l'usage habituellement attendu d'un bien
              semblable et, le cas échéant :
            </p>
            <ul>
              <li>
                s'il correspond à la description donnée par le vendeur et
                possède les qualités que celui-ci a présentées à l'acheteur sous
                forme d'échantillon ou de modèle ;
              </li>
              <li>
                s'il présente les qualités qu'un acheteur peut légitimement
                attendre eu égard aux déclarations publiques faites par le
                vendeur, par le producteur ou par son représentant, notamment
                dans la publicité ou l'étiquetage ;
              </li>
            </ul>
            <p>
              2° Ou s'il présente les caractéristiques définies d'un commun
              accord par les parties ou est propre à tout usage spécial
              recherché par l'acheteur, porté à la connaissance du vendeur et
              que ce dernier a accepté. »
            </p>
            <p>Art. L.217-7 du code de la consommation :</p>
            <p>
              « Les défauts de conformité qui apparaissent dans un délai de
              vingt-quatre mois à partir de la délivrance du bien sont présumés
              exister au moment de la délivrance, sauf preuve contraire.
            </p>
            <p>
              Pour les biens vendus d'occasion, ce délai est fixé à six mois.
            </p>
            <p>
              Le vendeur peut combattre cette présomption si celle-ci n'est pas
              compatible avec la nature du bien ou le défaut de conformité
              invoqué. »
            </p>
            <p>Art. L.217-9 du code de la consommation :</p>
            <p>
              « En cas de défaut de conformité, l'acheteur choisit entre la
              réparation et le remplacement du bien. Toutefois, le vendeur peut
              ne pas procéder selon le choix de l'acheteur si ce choix entraîne
              un coût manifestement disproportionné au regard de l'autre
              modalité, compte tenu de la valeur du bien ou de l'importance du
              défaut. Il est alors tenu de procéder, sauf impossibilité, selon
              la modalité non choisie par l'acheteur ».
            </p>
            <p>Art. L.217-12 du code de la consommation :</p>
            <p>
              « L'action résultant du défaut de conformité se prescrit par deux
              ans à compter de la délivrance du bien. »
            </p>
            <p>Art. 1641 du code civil :</p>
            <p>
              « Le vendeur est tenu de la garantie à raison des défauts cachés
              de la chose vendue qui la rendent impropre à l'usage auquel on la
              destine, ou qui diminuent tellement cet usage, que l'acheteur ne
              l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix,
              s'il les avait connus. »
            </p>
            <p>Art. 1644 du code civil :</p>
            <p>
              « Dans le cas des articles 1641 et 1643, l'acheteur a le choix de
              rendre la chose et de se faire restituer le prix, ou de garder la
              chose et de se faire rendre une partie du prix. »
            </p>
            <p>Art. 1648 alinéa 1er du code civil :</p>
            <p>
              « L'action résultant des vices rédhibitoires doit être intentée
              par l'acquéreur dans un délai de deux ans à compter de la
              découverte du vice. »
            </p>
            <p>
              Il est rappelé que la recherche de solutions amiables
              préalablement à une éventuelle action en justice n'interrompt pas
              les délais d'action des garanties légales ni la durée de toute
              éventuelle garantie contractuelle.
            </p>

            <h2>ARTICLE 14 – DONNÉES PERSONNELLES</h2>
            <p>
              Pour davantage d’informations concernant l’utilisation de données
              à caractère personnel par l’Exploitant, veuillez lire
              attentivement la Charte sur le respect de la vie privée (la «
              Charte »). Vous pouvez à tout moment consulter cette Charte sur le
              Site.
            </p>

            <h2>Article 15 – LIENS HYPERTEXTES</h2>
            <p>
              Les liens hypertextes disponibles sur le Site peuvent renvoyer
              vers des sites tiers non édités par l’Exploitant. Ils sont fournis
              uniquement pour la convenance du Client, afin de faciliter
              l’utilisation des ressources disponibles sur l’Internet. Si le
              Client utilise ces liens, il quittera le Site et acceptera alors
              d’utiliser les sites tiers à ses risques et périls ou le cas
              échéant conformément aux conditions qui les régissent.
            </p>
            <p>
              Le Client reconnait que l’Exploitant ne contrôle ni ne contribue
              en aucune manière à l’élaboration des conditions d’utilisation
              et/ou du contenu s’appliquant à ou figurant sur ces sites tiers.
            </p>
            <p>
              En conséquence, l’Exploitant ne saurait être tenu responsable de
              quelque façon que ce soit du fait de ces liens hypertextes.
            </p>
            <p>
              En outre, le Client reconnait que l’Exploitant ne saurait
              cautionner, garantir ou reprendre à son compte tout ou partie des
              conditions d’utilisation et/ou du contenu de ces sites tiers.
            </p>
            <p>
              Le Site peut également contenir des liens hypertextes
              promotionnels et/ou bandeaux publicitaires renvoyant vers des
              sites tiers non édités par l’Exploitant.
            </p>
            <p>
              L’Exploitant invite le Client à lui signaler tout lien hypertexte
              présent sur le Site qui permettrait d’accéder à un site tiers
              proposant du contenu contraire aux lois et/ou aux bonnes mœurs.
            </p>
            <p>
              Le Client ne pourra pas utiliser et/ou insérer de lien hypertexte
              pointant vers le site sans l’accord écrit et préalable de
              l’Exploitant au cas par cas.
            </p>

            <h2>ARTICLE 16 – RÉFÉRENCES</h2>
            <p>
              Le Client autorise l’Exploitant à mentionner le nom du Client, son
              logo à titre de référence dans ses supports de communication
              (plaquette, site internet, proposition commerciale, relation avec
              la presse, communiqué de presse, dossier de presse, communication
              interne, etc.).
            </p>

            <h2>ARTICLE 17 – DISPOSITION GÉNÉRALES</h2>
            <h2>INTEGRALITE DE L’ACCORD DES PARTIES</h2>
            <p>
              Les présentes Conditions Générales constituent un contrat
              régissant les relations entre le Client et l’Exploitant. Elles
              constituent l'intégralité des droits et obligations de la Société
              et de l’Exploitant relatifs à leur objet. Si une ou plusieurs
              stipulations des présentes Conditions Générales étaient déclarées
              nulles en application d'une loi, d'un règlement ou à la suite
              d'une décision définitive d'une juridiction compétente, les autres
              stipulations garderont toute leur force et leur portée. En outre,
              le fait pour une des parties aux présentes Conditions Générales de
              ne pas se prévaloir d'un manquement de l'autre partie à l'une
              quelconque des dispositions des présentes Conditions Générales ne
              saurait s'interpréter comme une renonciation de sa part à se
              prévaloir dans l'avenir d'un tel manquement.
            </p>

            <h2>MODIFICATIONS DES CONDITIONS</h2>
            <p>
              L’Exploitant se réserve le droit de modifier à tout moment et sans
              préavis le contenu du Site ou des services qui y sont disponibles,
              et/ou de cesser de manière temporaire ou définitive d’exploiter
              tout ou partie du Site.
            </p>
            <p>
              En outre, l’Exploitant se réserve le droit de modifier à tout
              moment et sans préavis la localisation du Site sur l’Internet,
              ainsi que les présentes Conditions Générales. Le Client est donc
              tenu par conséquent de se reporter aux présentes Conditions
              Générales avant toute utilisation du Site.
            </p>
            <p>
              Le Client reconnait que l’Exploitant ne saurait être tenu
              responsable de quelque manière que ce soit envers lui ou tout
              tiers du fait de ces modifications, suspensions ou cessations.
            </p>
            <p>
              L’Exploitant conseille au Client de sauvegarder et/ou imprimer les
              présentes Conditions Générales pour une conservation sûre et
              durable, et pouvoir ainsi les invoquer à tout moment pendant
              l'exécution du contrat si besoin.
            </p>

            <h2>RECLAMATION - MEDIATION</h2>
            <p>
              En cas de litige, vous devez vous adresser en priorité au service
              client de l'entreprise aux coordonnées suivantes :
              hello@lhight.fr.
            </p>
            <p>
              En cas d'échec de la demande de réclamation auprès du service
              client ou en l'absence de réponse de ce service dans un délai de
              dix (10) jours, le Client peut soumettre le différend relatif au
              bon de commande ou aux présentes CGV l'opposant à l’Exploitant au
              médiateur suivant : FEVAD 60 rue la Boétie, 75008 Paris.
            </p>
            <p>
              Le médiateur tentera, en toute indépendance et impartialité, de
              rapprocher les parties en vue d'aboutir à une solution amiable.
              Les parties restent libres d'accepter ou de refuser le recours à
              la médiation ainsi que, en cas de recours à la médiation,
              d'accepter ou de refuser la solution proposée par le médiateur.
            </p>

            <h2>DROIT APPLICABLE</h2>
            <p>
              Ces Conditions Générales sont régies, interprétées et appliquées
              conformément au droit français.
            </p>

            <h2>ACCEPTATION DES CONDITIONS GÉNÉRALES PAR LE CLIENT</h2>

            <p>
              Le Client reconnait avoir lu attentivement les présentes
              Conditions Générales.
            </p>
            <p>
              Les Conditions Générales applicables au Client sont celles
              disponibles à la date de la commande dont une copie datée à ce
              jour peut être remise à sa demande au Client, il est donc précisé
              que toute modification des Conditions Générales qui serait
              effectuée par l’Exploitant ne s’appliquera pas à toute commande
              intervenue antérieurement, sauf accord exprès du Client à
              l’origine d’une commande donnée.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "terms",
};
</script>
<style lang="scss">
@import "@/components/terms/_index.scss";
</style>
